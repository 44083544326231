import React from "react";

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FileViewer: React.FC<{ product: any }> = ({ product }) => {

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="overflow-hidden h-[50vh] md:h-[150vh]">
      <div style={{ height: '100%', overflow: 'auto' }}>
        <Viewer
          theme="dark"
          fileUrl={require(`./../../assets/media/files/${product}.pdf`)}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </div>
  );
};

export default FileViewer;