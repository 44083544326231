import React from "react";
import { LogoProps } from "../interfaces/logo.interface";
import { Link } from "react-router-dom";
import focus from "./../../assets/media/logo/focus.svg"

const LogoComponent:React.FC<LogoProps> = ({ src, type, path, name, svg }) => {
  return (
    <div className="logo">
      <div className="logo-wrapper">
        <Link to={path ? path : "#logo"}>
          {
            type === "logo" ? <img src={src} alt="logo" /> : null 
          }
          {
            type === "text" ? <div className="text-2xl uppercase font-eurostile h-6">{name}</div> : null
          }
          {
            type === "svg" ? <img className="w-full h-10" src={focus} alt="logo" /> : null
          }
        </Link>
      </div>
    </div>
  )
}

export default LogoComponent