import React from "react"
import DividerComponent from "../../shared/components/divider.component"
import { Link } from "react-router-dom"
import { menuItemsData } from "../../shared/data/menuItems.data"
import { MenuProps } from "../../shared/interfaces/menuItem.interface"
import LogoComponent from "../../shared/components/logo.component"
import { RiFacebookFill } from "react-icons/ri";
import { RiInstagramLine } from "react-icons/ri";
import { RiTwitterXLine } from "react-icons/ri";
import { RiThreadsLine } from "react-icons/ri";


const FooterComponent: React.FC = () => {

  const ListItems: React.FC<{ title: string, navs: any }> = ({ title, navs }) => {
    return (
      <div className="flex flex-col gap-2">
        <h2 className="uppercase text-base font-semibold">{title}</h2>
        <ul className="!m-0 !p-0">
          {navs.map((menu: MenuProps, index: number) => {
            return (
              <li key={index} className="nav-item group">
                <Link className="text-xs font-light" to={menu.path ? menu.path : '#item'}>
                  <span className={"group-hover:text-focus-primary transition ease-in-out"}>{menu.name}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className="footer">
      <div className="footer-wrapper px-5 pt-10 border-t border-white">

        <section className="pre-footer-section mb-5 px-10 md:px-40">
          <div className="section-wraper flex justify-center">
            <div className="content">
              <div className="grid grid-cols-1 sm:grid-cols-2 xl:flex gap-10 md:gap-20">

                <div className="products-categories">
                  <ListItems
                    title="Nos produits"
                    navs={menuItemsData[1].children && menuItemsData[1].children[2].children} />
                </div>
                <div className="useful-links">
                  <ListItems
                    title="Lien utils"
                    navs={[
                      {
                        name: 'Service client',
                        mobile: true,
                        type: "plain",
                        path: '/support'
                      },
                      {
                        name: 'Distributeur',
                        mobile: true,
                        type: "plain",
                        path: "/distributor"
                      },
                    ]} />
                </div>
                <div className="site-plan">
                  <ListItems
                    title="Plan du site"
                    navs={[
                      {
                        name: 'Qui sommes nous',
                        mobile: true,
                        type: "plain",
                        path: "/about"
                      },
                      {
                        name: 'Nos produits',
                        mobile: true,
                        type: "plain",
                        path: "/products"
                      },
                      {
                        name: 'Notre catalogue',
                        mobile: true,
                        type: "plain",
                        path: "/catalog"
                      },
                      {
                        name: 'Support',
                        mobile: true,
                        type: "plain",
                        path: "/support"
                      }
                    ]} />
                </div>
                <div className="site-plan">
                  <ListItems
                    title="La société"
                    navs={[
                      /* {
                        name: 'Capital: 2 500 000 DT',
                        mobile: true,
                        type: "plain",
                        path: "#item"
                      }, */
                      {
                        name: 'Tunis : Rue Ibn Jazzar, Sidi Daoud 2046 La Marsa Tunis - Tunisie',
                        mobile: true,
                        type: "plain",
                        path: "#item"
                      },
                      {
                        name: 'Sfax : Route Gremda Ceinture Bourguiba Km 3 – 3062 Sfax',
                        mobile: true,
                        type: "plain",
                        path: "#item"
                      },
                      /* {
                        name: 'IBAN: TN59 25000000000000007087',
                        mobile: true,
                        type: "plain",
                        path: "#item"
                      }, */
                    ]} />
                </div>
                <div className="social-links col-span-1 xl:col-span-2">
                  <h3 className="uppercase font-semibold mb-4">Réseaux sociaux</h3>
                  <div className="flex gap-5">
                    <Link className="w-8 h-8 bg-white fill-[#444] flex rounded-lg justify-center items-center" to="https://www.facebook.com/focusline2000/" target="_blank">
                      <RiFacebookFill className="fill-inherit text-2xl" />
                    </Link>
                    <Link className="w-8 h-8 bg-white fill-[#444] flex rounded-lg justify-center items-center" to="https://www.instagram.com/focuslinetunisie" target="_blank">
                      <RiInstagramLine className="fill-inherit text-2xl" />
                    </Link>
                    {/* <Link className="w-8 h-8 bg-white fill-[#444] flex rounded-lg justify-center items-center" to="#" target="_blank">
                      <RiTwitterXLine className="fill-inherit text-2xl" />
                    </Link>
                    <Link className="w-8 h-8 bg-white fill-[#444] flex rounded-lg justify-center items-center" to="#" target="_blank">
                      <RiThreadsLine className="fill-inherit text-2xl" />
                    </Link> */}
                  </div>
                  {/* <ListItems title="Suivez-nous" navs={[]} /> */}
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="footer-section">
          <div className="section-wraper ">
            <div className="content">
              <DividerComponent />
              <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 text-xs text-center items-center justify-center py-2">
                {/* <LogoComponent name="Focus" type="svg" path="/" /> */}
                <span>© 2024 Focus. Tous droits réservés.</span>
                {/* <span>Terms & conditions</span> */}
              </div>
            </div>
          </div>
        </section>


      </div>
    </div>
  )

}

export default FooterComponent