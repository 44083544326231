
import { Input } from "antd";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { productList } from "../../shared/data/productsList.data";


const SearchComponent: React.FC<{ className: string, setShowSearch: any, showSearch:boolean }> = ({ className, setShowSearch, showSearch }) => {

  const [searchInput, setSearchInput] = React.useState("")

  const ProductListComponent: React.FC<{ search: string }> = ({ search = "" }) => {

    const [filtredProducts, setFiltredProducts] = React.useState([]) as any

    React.useEffect(() => {

      if ((search != null || search != "") && showSearch) {

        const filteredProducts = productList.filter(product => {
          if (search != "" && search != null && product['Nom'].toLowerCase().includes(search.toLowerCase())) {
            return true;
          }

          if (search != "" && search != null && product['Catégories'].toLowerCase().includes(search.toLowerCase())) {
            return true;
          }

          if (search != "" && search != null && product['Étiquettes'].toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        });


        setFiltredProducts(filteredProducts)


      } else {
        setFiltredProducts([])
      }

    }, [search])

    return (
      <ul className="px-5 overflow-y-auto max-h-72">
        {
          filtredProducts.map((data: any, idx:number) => {
            return (
              <li key={idx}>
                <Link onClick={()=>setShowSearch(false)} to={"/products/"+data['ID']}>
                  <div className="flex items-center gap-5 p-2">
                    <img className="w-20 h-20 object-cover" src={data['Images']} onError={({ currentTarget })=>{currentTarget.src = "https://placehold.co/512"}} alt="product-img" />
                    <div className="flex flex-col">
                      <h3 className="text-sm">{data['Nom']}</h3>
                      <p className="text-xs">{data['Étiquettes']}</p>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })
        }
      </ul>
    )
  }

  return (
    <div className="header-search">
      <div className="wrapper">
        <RiSearchLine onClick={()=> setShowSearch(!showSearch)} className={className + " fill-focus-primary cursor-pointer"} />
        <div className={`absolute left-0 top-[70px] z-10 bg-[#444] overflow-hidden w-full transition-all duration-500 ease-in-out ${!showSearch ? 'h-0' : 'h-fit p-5'}`}>
          <Input onChange={(e: any) => setSearchInput(e.target.value)} value={searchInput} className="outline-none mb-5 shadow-none border-none !bg-[#333] placeholder:text-white placeholder:text-xs text-white" placeholder="Rechercher produit par référence" />
          <ProductListComponent search={searchInput} />
        </div>
      </div>
    </div>
  )

}

export default SearchComponent