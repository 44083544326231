export const productList = [
  {
    "ID": 971,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 66",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 60 cm</li><li>13 fonctions dont «PIZZA» &amp; «PAIN»</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Muniterie electronique</li><li>Autonettoyant Catalyse</li><li>Commande tactile</li><li>Rail telescopique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "13 Fonctions, 60 Cm, 69 Litres, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 66.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 66",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "13 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "69 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1374,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 66",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox foncé 60 cm</li><li>13 fonctions dont «PIZZA» &amp; «PAIN»</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Muniterie electronique</li><li>Autonettoyant Catalyse</li><li>Commande tactile</li><li>Rail telescopique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "13 Fonctions, 60 Cm, 69 Litres, Dark Inox, Electrique",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART  66.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 66",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Dark Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "13 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "69 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1375,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 56",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 60 cm</li><li>11 programmes</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Minuterie électronique</li><li>Autonettoyant Catalyse</li><li>Rail telescopique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "11 fonctions, 60 Cm, 69 Litres, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 56.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 56",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "11 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "69 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1377,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 66",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction noir 60 cm</li><li>13 fonctions dont «PIZZA» &amp; «PAIN»</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Muniterie electronique</li><li>Autonettoyant Catalyse</li><li>Commande tactile</li><li>Rail telescopique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "13 Fonctions, 60 Cm, 69 Litres, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 66.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 66",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "13 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "69 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1402,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 56",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox foncé 60 cm</li><li>11 programmes</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Minuterie électronique</li><li>Autonettoyant Catalyse</li><li>Rail telescopique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "11 fonctions, 60 Cm, 69 Litres, Dark Inox, Electrique",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 56.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 56",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Dark Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "11 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "69 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1403,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 56",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction noir 60 cm</li><li>11 programmes</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Minuterie électronique</li><li>Autonettoyant Catalyse</li><li>Rail telescopique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "11 fonctions, 60 Cm, 69 Litres, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 56.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 56",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "11 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "69 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1405,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 721X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 60 cm</li><li>11 programmes</li><li>Boutons «PULL»</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 Plateaux / 1 Grille</li><li>Autonettoyant catalyse</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "11 fonctions, 60 Cm, 73 Litres, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 721X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 721X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "11 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "73 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1406,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 522X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 60 cm</li><li>9 programmes</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 Plateau / 1 grille</li><li>Minuterie électronique</li><li>Autonettoyant Catalyse</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "60 Cm, 65 Litres, 9 Fonctions, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 522X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 522X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "9 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "65 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1407,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 521X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 60 cm</li><li>7 programmes</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 plateu / 1 grille</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "58 Litres, 60 Cm, 7 Fonctions, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 521X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 521X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "7 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "58 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1408,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 525X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 60 cm</li><li>9 programmes</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 Plateu / 1 grille</li><li>Minuterie mécanique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "58 Litres, 60 Cm, 9 Fonctions, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 525X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 525X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "9 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "58 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1409,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 721B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction noir 60 cm</li><li>11 programmes</li><li>Boutons «PULL»</li><li>Chaleur tournante</li><li>Porte 3 vitres</li><li>2 plateaux / 1 grille</li><li>Autonettoyant catalyse</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "11 fonctions, 60 Cm, 73 Litres, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 721B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 721B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "11 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "73 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1410,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 525B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction noir 60 cm</li><li>9 programmes</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 plateau / 1 grille</li><li>Minuterie mécanique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "58 Litres, 60 Cm, 9 Fonctions, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 525B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 525B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "9 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "58 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1412,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 521W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction blanc 60 cm</li><li>7 programmes</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 plateau / 1 grille</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "58 Litres, 60 Cm, 7 Fonctions, Blanc, Electrique",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 521W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 521W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Fonctions",
    "Valeur(s) de l’attribut 3": "7 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "58 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1413,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 534X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Gaz / Gaz ventilé inox 60 cm</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 plateau / 1 grille</li><li>Autonettoyant catalyse</li><li>Tourne broche</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "60 Cm, 65 Litres, Gaz / Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 534X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 534X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "65 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Gaz / Gaz",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1414,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 536X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Gaz / Gaz ventilé inox 60 cm</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 plateau / 1 grille</li><li>Tourne broche</li><li>Minuterie mécanique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "60 Cm, 65 Litres, Gaz / Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 536X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 536X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "65 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Gaz / Gaz",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1415,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 536B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Gaz / Gaz ventilé noir 60 cm</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>1 Plateau / 1 grille</li><li>Tourne broche</li><li>Minuterie mécanique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "60 Cm, 65 Litres, Gaz / Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 536B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 536B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "65 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Gaz / Gaz",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1417,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 795X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction inox 90 cm</li><li>10 programmes</li><li>Chaleur tournante avec 2 ventilateurs</li><li>Porte 2 vitres</li><li>2 plateaux / 1 grille</li><li>Minuterie électronique</li><li>Pull booton</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "10 Fonctions, 105 Litres, 90 Cm, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 795X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 795X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr de fonctions",
    "Valeur(s) de l’attribut 3": "10 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "105 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "90 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electronique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1418,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 795B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Multifonction noir 90 cm</li><li>10 programmes</li><li>Chaleur tournante avec 2 ventilateurs</li><li>Porte 2 vitres</li><li>2 plateaux / 1 grille</li><li>Minuterie électronique</li><li>Pull booton</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "10 Fonctions, 105 Litres, 90 Cm, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 795B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 795B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr de fonctions",
    "Valeur(s) de l’attribut 3": "10 Fonctions",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Volume",
    "Valeur(s) de l’attribut 4": "105 Litres",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "90 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Electronique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1419,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 793X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Gaz / Gaz ventilé inox 90 cm</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>2 plateaux / 1 grille</li><li>Tourne broche</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "105 Litres, 90 Cm, Gaz / Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 793X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 793X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "105 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "90 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Gaz / Gaz",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1420,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 793B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Gaz / gaz ventilé noir 90 cm</li><li>Chaleur tournante</li><li>Porte 2 vitres</li><li>2 plateaux / 1 grille</li><li>Tourne broche</li><li>Minuterie électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "FOURS",
    "Étiquettes": "105 Litres, 90 Cm, Gaz / Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 793 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 793B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "105 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "90 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Gaz / Gaz",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1421,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 50",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril Inox 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle Tactile</li><li>Ouverture de la porte electronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "25 Litres, 39 Cm, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 50.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 50",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "25 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1422,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 30",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril Inox foncé 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle Tactile</li><li>Ouverture de la porte électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "25 Litres, 39 Cm, Dark Inox",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 30.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 30",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Dark Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "25 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1425,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 40",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril Noir 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle Tactile</li><li>Ouverture de la porte électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "25 Litres, 39 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 40.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 40",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "25 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1426,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 25B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril noir 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "25 Litres, 39 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 25B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 25B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "25 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1428,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 23B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril noir 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle mécanique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "20 Litres, 39 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 23B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 23B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "20 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1429,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 25X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril inox 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "25 Litres, 39 Cm, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 25X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 25X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "25 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1430,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 23X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Micro-ondes Gril inox 39 cm</li><li>Porte en vitre</li><li>Cavité Inox</li><li>5 niveaux de puissance</li><li>Verrouillage enfant</li><li>La fonction de dégivrage en poids</li><li>Contrôle électronique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MICRO-ONDES",
    "Étiquettes": "20 Litres, 39 Cm, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 23X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 23X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Volume",
    "Valeur(s) de l’attribut 3": "20 Litres",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "39 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Grill",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1501,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 60",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 cm</li><li>4 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 60.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 60",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1502,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4015BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 cm</li><li>4 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4015BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4015BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1503,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 415B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 cm</li><li>4 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 415B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 415B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1505,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4005BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 CM</li><li>4 Brûleurs à gaz, dont un double Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4005BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4005BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1506,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 405B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 CM</li><li>4 Brûleurs à gaz, dont un double Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 405B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 405B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1507,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 404B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 CM</li><li>4 Brûleurs à gaz, dont un double Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 404B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 404B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1509,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 409B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 409B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 409B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1510,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 60",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>FILOTOP Inox 60 cm</li><li>4 Brûleurs à gaz dont un double couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 60.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 60",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1511,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 60",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz dont un double couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 60.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 60",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1512,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 804X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz, dont un double couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 804X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 804X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1513,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4002XS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4002XS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4002XS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1514,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 402X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 402X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4002XS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1515,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4008XS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4008XS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4008XS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1516,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 408X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 408X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 408X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1517,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 401X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 401X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 401X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1518,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 400X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 400X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 400X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1519,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 806X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>3 Brûleurs à gaz</li><li>1 foyer électrique</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz / Electrique, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 806X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 806X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz / Electrique",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1520,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 406W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro blanc 70 cm</li><li>5 Brûleurs à gaz, dont un Triple Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Blanc, Gaz, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 406W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 406W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1521,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 405W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro blanc 60 cm</li><li>4 Brûleurs à gaz, dont un double Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 405W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 405W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1522,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4008BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4008BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4008BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1523,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 408B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 408B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 408B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1524,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 401B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 60 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 401B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 401B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "60 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1525,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 75",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 75 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 75 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 75.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 75",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "75 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1526,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4006BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 70 cm</li><li>5 Brûleurs à gaz, dont Triple Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4006BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4006BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1527,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 406B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 70 cm</li><li>5 Brûleurs à gaz, dont Triple Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 406B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 406B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1528,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4017BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 40179BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4017BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1529,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 417B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 417B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 417B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1530,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 410B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 70 cm</li><li>4 Brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 70 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 410B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 410B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1531,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 75",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>FILOTOP Inox 75 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 75 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 75.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 75",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "75 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1533,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 75",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 75 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 75 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 75.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 75",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "75 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1534,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 811X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 75 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 75 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 811X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 811X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "75 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1535,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4003XS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4003XS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4003XS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1536,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 403X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 403X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 403X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1537,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4078XS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4078XS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4078XS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1538,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 478X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 478X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 478X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1539,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 407X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 70 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 407X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 407X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1540,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4078BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 70 cm</li><li>5 Brûleurs à gaz, dont un Triple Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4078BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4078BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1541,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 478B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 70 cm</li><li>5 Brûleurs à gaz, dont un Triple Couronnes</li><li>Flamme stabilisée</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage Automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 70 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 478B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 478B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "70 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1542,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 818B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 90 cm</li><li>Modèle de coin</li><li>4 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 90 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 818B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 818B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "90 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1543,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 8179B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 90 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 8179B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 8179B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "90 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1544,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4179B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 86 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4179B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4179B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "86 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1545,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 40179BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 86 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 40179BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 40179BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "86 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1546,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4109B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 86 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4109B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4109B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "86 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1548,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 90",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 90 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 90.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 90",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "90 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1549,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 817X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 90 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 817X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 817X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "90 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1550,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 40789XS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 86 Cm, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 40789XS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 40789XS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "86 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1552,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 4789X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 86 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 4789X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 4789X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "86 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1553,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 40789BS",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 90 cm</li><li>5 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "5 Feux, 86 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 40789BS.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 40789BS",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 5,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "86 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1555,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 813B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Noir 30 cm</li><li>2 Bruleurs à gaz</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "2 Feux, 30 Cm, Gaz, Noir, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 813B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 813B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "30 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1556,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 124",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>FILOTOP Inox 110 cm</li><li>4 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "110 Cm, 4 Feux, Avec Thermocouple, Gaz, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 124.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 124",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "110 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1557,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 104",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 100 cm</li><li>4 Brûleurs à gaz dont un triple couronnes</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "110 Cm, 4 Feux, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 104.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 104",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 4,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "110 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1558,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 31",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 30 cm</li><li>Un brûleur à gaz triple couronnes</li><li>Flamme stabilisé</li><li>Grille et chapeau de brûleur en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "1 Feu, 30 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 31.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 31",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "30 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1559,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 32",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro noir 30 cm</li><li>2 brûleurs à gaz</li><li>Flamme stabilisé</li><li>Grilles et chapeaux de brûleurs en fonte</li><li>Allumage automatique</li><li>Sécurité gaz sur chaque brûleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "2 Feux, 30 Cm, Avec Thermocouple, Gaz, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 32.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 32",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "30 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1560,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 33",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro céramique noir 30 cm</li><li>2 foyers radiants</li><li>1700 W &amp; 1200 W</li><li>Contrôle sensitif</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "2 Feux, 30 Cm, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 33.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 33",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Feux",
    "Valeur(s) de l’attribut 3": 2,
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "30 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Electrique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1562,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 38",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Teppanyaki 38 cm</li><li>Inox</li><li>Commande frontale</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "1 Feu, 38 Cm, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 38.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 38",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Feux",
    "Valeur(s) de l’attribut 3": 1,
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "38 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Electrique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1563,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 816X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Vitro céramique 60 cm</li><li>4 Foyers radiants</li><li>Contrôle sensitif</li><li>Sécurité enfant</li><li>Indicateurs de chaleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 816X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 816X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Feux",
    "Valeur(s) de l’attribut 3": 4,
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Electrique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1564,
    "Type": "simple",
    "UGS": "",
    "Nom": "TORNADO",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Induction 60 cm</li><li>4 Foyers inductions</li><li>Contrôles sensitifs</li><li>Sécurité enfant</li><li>Indicateurs de chaleur</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Electrique, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/TORNADO.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "TORNADO",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Feux",
    "Valeur(s) de l’attribut 3": 4,
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Electrique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1565,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 814X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 60 cm</li><li>4 foyers électriques</li><li>Commandes latérales</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "4 Feux, 60 Cm, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 814X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 814X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Feux",
    "Valeur(s) de l’attribut 3": 4,
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Electrique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1566,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 812X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 30 cm</li><li>2 foyers électriques</li><li>Commandes frontales</li><li>2000 W &amp; 1000 W</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "2 Feux, 30 Cm, Electrique, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 812X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 812X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Nbr Feux",
    "Valeur(s) de l’attribut 3": 2,
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "30 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Electrique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1568,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 813X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Inox 30 cm</li><li>2 Bruleurs à gaz</li><li>Grilles et chapeaux de brûleurs émaillés</li><li>Allumage automatique</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "PLAQUES",
    "Étiquettes": "2 Feux, 30 Cm, Gaz, INOX, Sans Thermocouple",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 813X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 813X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Avec Thermocouple",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Feux",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Taille",
    "Valeur(s) de l’attribut 5": "30 Cm",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Type",
    "Valeur(s) de l’attribut 6": "Gaz",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1570,
    "Type": "simple",
    "UGS": "",
    "Nom": "LAGO",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 50 cm</li><li>Inox 1100 m3/h</li><li>Touches souples 3 vitesses</li><li>2 Spots hallogène x 20W</li><li>Filtre aluminium lavable</li><li>Filtre en charbon inclus</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 50 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/LAGO.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "LAGO",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "50 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1571,
    "Type": "simple",
    "UGS": "",
    "Nom": "LAGO B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 50 cm</li><li>Noir 1100 m3/h</li><li>Touches souples 3 vitesses</li><li>2 Spots hallogène x 20W</li><li>Filtre aluminium lavable</li><li>Filtre en charbon inclus</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 50 Cm, Centrale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/LAGO B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "LAGO B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "50 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1572,
    "Type": "simple",
    "UGS": "",
    "Nom": "VASTO",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 39 cm</li><li>Aspiration périmétrale</li><li>Inox + vitro noir 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 3 vitesses + 1 turbo</li><li>4 Spots LED x 3W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 39 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/VASTO.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "VASTO",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "39 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1573,
    "Type": "simple",
    "UGS": "",
    "Nom": "VNOVA B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 39 cm</li><li>Noir 1100 m3/h</li><li>3 vitesses</li><li>2 Spots LED x 3W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 39 Cm, Centrale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/VNOVA B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "VNOVA B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "39 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1574,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 9040",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 / 120 cm</li><li>Inox + vitro 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 4 vitesses + 1 turbo</li><li>4 Spots LED x 3W</li><li>Filtres Inox</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 9040.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 9040",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1575,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 1020",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 / 120 cm</li><li>Inox + vitro 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 4 vitesses + 1 turbo</li><li>4 Spots LED x 3W</li><li>Filtres Inox</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 120 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 1020.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 1020",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "120 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1576,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 9000",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 / 120 cm</li><li>Inox + vitro 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 4 vitesses + 1 turbo</li><li>4 Spots LED x 3W</li><li>Filtres Inox</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 9000.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 9000",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1577,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 1120",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 / 120 cm</li><li>Inox + vitro 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 4 vitesses + 1 turbo</li><li>4 Spots LED x 3W</li><li>Filtres Inox</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 120 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 1120.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 1120",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "120 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1578,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 915X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 cm</li><li>Inox + vitro 850 m3/h</li><li>3 vitesses</li><li>2 spots halogène x 20W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 915X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 915X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1579,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 917X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 cm</li><li>Inox + vitro 850 m3/h</li><li>3 vitesses</li><li>2 spots halogène x 20W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 917X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 917X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1580,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 919X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Centrale 90 cm</li><li>Inox 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 3 vitesses + 1 turbo</li><li>4 Spots Halogène x 20W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionne</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, Centrale, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 919X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 919X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Centrale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1581,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 1000X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Coin 100 cm x 100 cm</li><li>Inox 1100 m3/h</li><li>Touches 3 vitesses + 1 turbo</li><li>4 Spots LED x 3W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "100 Cm, 1100 m3/H, Coin, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 1000X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 1000X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "100 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Coin",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1582,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 6000",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Encastrable 60 / 90 cm</li><li>Inox 850 m3/h</li><li>Rotative 3 vitesses</li><li>2 Spots LED x 2.5W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 850 m3/H, Coin, Encastrable, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 6000.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 6000",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Encastrable",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1583,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 9000",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Encastrable 60 / 90 cm</li><li>Inox 850 m3/h</li><li>Rotative 3 vitesses</li><li>2 Spots LED x 2.5W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, Encastrable, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 9000.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 9000",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Encastrable",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1584,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 630X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Encastrable 52 / 72 cm</li><li>Gris 550 m3/h</li><li>Commande Slider</li><li>3 vitesses</li><li>Eclairage : LED 2 x 3W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 120 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "52 Cm, 550 m3/H, Encastrable, Silver",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 630X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 630X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Silver",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "52 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "550 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Encastrable",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1585,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 930X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Encastrable 52 / 72 cm</li><li>Gris 550 m3/h</li><li>Commande Slider</li><li>3 vitesses</li><li>Eclairage : LED 2 x 3W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 120 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "550 m3/H, 72 Cm, Encastrable, Silver",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 930X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 930X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Silver",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "72 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "550 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Encastrable",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1586,
    "Type": "simple",
    "UGS": "",
    "Nom": "BARETTA",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 90 cm</li><li>Vitro noir 1100 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>Touches 3 vitesses + 1 turbo</li><li>2 spots halogène x 20W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/BARETTA.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "BARETTA",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1587,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 911B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 90 cm</li><li>Vitro noir 1100 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>Touches 3 vitesses + 1 turbo</li><li>2 spots halogène x 20W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 911B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 911B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1588,
    "Type": "simple",
    "UGS": "",
    "Nom": "GALAXY 90",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 90 cm</li><li>Noir 850 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>3 vitesses</li><li>Touches sensitives</li><li>Eclairage : 2 Spots LED x 50W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/GALAXY 90.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "GALAXY 90",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1589,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 920B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 90 cm</li><li>Noir 850 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>3 vitesses</li><li>Touches sensitives</li><li>Eclairage : 2 Spots LED x 50W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 920B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 920B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1590,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 9050",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Décorative 90 cm</li><li>Inox + vitro 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 4 vitesses + 1 turbo</li><li>2 Spots LED x 3W</li><li>Filtres Inox</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 9050.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 9050",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1591,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 9010",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Décorative 90 cm</li><li>Inox + vitro 1100 m3/h</li><li>Afficheur numérique</li><li>Touches 4 vitesses + 1 turbo</li><li>2 Spots LED x 3W</li><li>Filtres Inox</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 90 Cm, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 9010.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 9010",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1592,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 918X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Décorative 90 cm</li><li>Inox 850 m3/h</li><li>3 vitesses</li><li>2 spots halogène x 20W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 918X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 918X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1593,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 914X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Décorative 90 cm</li><li>Inox + vitro 850 m3/h</li><li>3 vitesses</li><li>2 spots halogène x 20W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 918X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 914X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1594,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 905X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Pyramidale 90 cm</li><li>Inox 600 m3/h</li><li>3 vitesses</li><li>Lampe 28W LED</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "600 m3/H, 90 Cm, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 905X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 905X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "600 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1595,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 905B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Pyramidale 90 cm</li><li>Noir 600 m3/h</li><li>3 vitesses</li><li>Lampe 28W LED</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "600 m3/H, 90 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 905B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 905B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "600 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1596,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 902X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette 90 cm</li><li>Inox 480 m3/h</li><li>3 vitesses</li><li>2 lampes x 40W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "480 m3/H, 90 Cm, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 902X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 902X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "480 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1597,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 902B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette 90 cm</li><li>Noir 480 m3/h</li><li>3 vitesses</li><li>2 lampes x 40W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "480 m3/H, 90 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 902B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 902B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "480 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1598,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 902W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette 90 cm</li><li>Blanc 480 m3/h</li><li>3 vitesses</li><li>2 lampes x 40W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "480 m3/H, 90 Cm, Blanc, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 902W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 902W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "480 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1599,
    "Type": "simple",
    "UGS": "",
    "Nom": "GALAXY 90W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 90 cm</li><li>blanc 850 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>3 vitesses</li><li>Touches sensitives</li><li>Eclairage : 2 Spots LED x 50W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "850 m3/H, 90 Cm, Blanc, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/GALAXY W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "GALAXY 90W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "90 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1600,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 611B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 60 cm</li><li>Vitro noir 1100 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>Touches 3 vitesses + 1 turbo</li><li>2 spots halogène x 20W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "1100 m3/H, 60 Cm, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 611B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 611B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "1100 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1601,
    "Type": "simple",
    "UGS": "",
    "Nom": "GALAXY 60",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 60 cm</li><li>Vitro noir 850 m3/h</li><li>Aspiration périmétrale</li><li>Afficheur numérique</li><li>3 vitesses</li><li>Touches sensitives</li><li>Eclairage : Spots LED x 50W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 850 m3/H, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/GALAXY 60.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "GALAXY 60",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1602,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 620B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Design 60 cm</li><li>Viro noir 850 m3/h</li><li>Aspiration périmétrale</li><li>Boutons</li><li>3 vitesses</li><li>Eclairage : Spots LED x 50W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 850 m3/H, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 620B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 620B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1603,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 618X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Décorative 60 cm</li><li>Inox 850 m3/h</li><li>3 vitesses</li><li>2 spots halogène x 20W</li><li>Filtre aluminium lavable</li><li>Diamètre évacuation : 150 mml</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 850 m3/H, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 618X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 618X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "850 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1604,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 605X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Pyramidale 60 cm</li><li>Inox 600 m3/h</li><li>3 vitesses</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 600 m3/H, INOX, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 605X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 605X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "600 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1605,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 605B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Pyramidale 60 cm</li><li>Noir 600 m3/h</li><li>3 vitesses</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 600 m3/H, Murale, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 605B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 605B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "600 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1606,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 605W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Pyramidale 60 cm</li><li>Blanc 600 m3/h</li><li>3 vitesses</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 150 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "60 Cm, 600 m3/H, Blanc, Murale",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 605W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 605W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "600 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Murale",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1607,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 602X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette Inox 60 cm</li><li>3 vitesses 480 m3/h</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "480 m3/H, 60 Cm, INOX, Sous-Meuble",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 602X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 602X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "480 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Sous-Meuble",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1608,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 602B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette Noir 60 cm</li><li>3 vitesses 480 m3/h</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "480 m3/H, 60 Cm, Noir, Sous-Meuble",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 602B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 602B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "480 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Sous-Meuble",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1609,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 602W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette Blanc 60 cm</li><li>3 vitesses 480 m3/h</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "480 m3/H, 60 Cm, Blanc, Sous-Meuble",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 602W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 602W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "480 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Sous-Meuble",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1610,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 600X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Casquette Inox 60 cm</li><li>3 vitesses 350 m3/h</li><li>Lampe 28W</li><li>Filtres aluminium lavables</li><li>Diamètre évacuation : 125 mm</li><li>Filtre en charbon optionnel</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 0,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "HOTTES",
    "Étiquettes": "350 m3/H, 60 Cm, INOX, Sous-Meuble",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 600X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 600X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Puissance Aspiration",
    "Valeur(s) de l’attribut 4": "350 m3/H",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Type",
    "Valeur(s) de l’attribut 5": "Sous-Meuble",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1611,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 1310",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Semi-encastrable 14 couverts</li><li>8 programmes + 6 températures</li><li>Ecran LED</li><li>Verrouillage enfant</li><li>Demi-charge</li><li>Départ différé 1 – 24 h</li><li>Puissance acoustique : 44 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 9 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "14 Couverts, 60 Cm, 8 Programmes, 9 Litres, INOX, Semi Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 1310.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 1310",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Installation",
    "Valeur(s) de l’attribut 2": "Semi Encastrable",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "60 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr de Couverts",
    "Valeur(s) de l’attribut 4": 14,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Programmes",
    "Valeur(s) de l’attribut 5": 8,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Consommation Eau",
    "Valeur(s) de l’attribut 6": "9 Litres",
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Couleur",
    "Valeur(s) de l’attribut 7": "Inox",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1612,
    "Type": "simple",
    "UGS": "",
    "Nom": "SOFT 1310",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Semi-encastrable 14 couverts</li><li>8 programmes + 6 températures</li><li>Ecran LED</li><li>Verrouillage enfant</li><li>Demi-charge</li><li>Départ différé 1 – 24 h</li><li>Puissance acoustique : 44 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 9 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "14 Couverts, 60 Cm, 8 Programmes, 9 Litres, Noir, Semi Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SOFT 1310.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SOFT 1310",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Semi Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 14,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 8,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "9 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1613,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 1310",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Totalement encastrable 15 couverts</li><li>8 programmes + 6 températures</li><li>Ecran LED</li><li>Verrouillage enfant</li><li>Demi-charge</li><li>Départ différé 1 – 19 h</li><li>Puissance acoustique : 44 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 9 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "15 Couverts, 60 Cm, 8 Programmes, 9 Litres, Noir, Totalement Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 1310.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 1310",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Totalement Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 15,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 8,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "9 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1614,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 501X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Totalement encastrable 12 couverts</li><li>5 programmes + 4 températures</li><li>Ecran LED</li><li>Verrouillage enfant</li><li>Demi-charge</li><li>Départ différé 3 – 6 – 9 h</li><li>Puissance acoustique : 49 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 12 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "12 Couverts, 12 Litres, 5 Programmes, 60 Cm, Silver, Totalement Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 501X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 501X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Silver",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Totalement Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 12,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 5,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "12 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1615,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 502X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Semi-encastrable 12 couverts</li><li>8 programmes + 6 températures</li><li>Ecran LED</li><li>Verrouillage enfant</li><li>Demi-charge</li><li>Départ différé 1 – 24 h</li><li>Puissance acoustique : 49 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 9 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "12 Couverts, 60 Cm, 8 Programmes, 9 Litres, Semi Encastrable, Silver",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 502X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 502X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Silver",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Semi Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 12,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 8,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "9 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1616,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 502B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Semi-encastrable 12 couverts</li><li>8 programmes + 6 températures</li><li>Ecran LED</li><li>Verrouillage enfant</li><li>Demi-charge</li><li>Départ différé 1 – 24 h</li><li>Puissance acoustique : 49 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 9 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "12 Couverts, 60 Cm, 8 Programmes, 9 Litres, Noir, Semi Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 502B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 502B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Semi Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 12,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 8,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "9 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1618,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 500X",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Semi-encastrable 12 couverts</li><li>4 programmes + 4 températures</li><li>Verrouillage enfant</li><li>Départ différé 3 – 6 – 9 – 12 h</li><li>Puissance acoustique : 57 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 12 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "12 Couverts, 12 Litres, 4 Programmes, 60 Cm, INOX, Semi Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 500X.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 500X",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Semi Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 12,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 4,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "12 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1619,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 500B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Semi-encastrable 12 couverts</li><li>4 programmes + 4 températures</li><li>Verrouillage enfant</li><li>Départ différé 3 – 6 – 9 – 12 h</li><li>Puissance acoustique : 57 dB(A)</li><li>Dimensions : 82 x 60 x 57</li><li>Pieds réglages</li><li>Faible Consommation : 12 Litres</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES VAISSELLE",
    "Étiquettes": "12 Couverts, 12 Litres, 4 Programmes, 60 Cm, Noir, Semi Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 500B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 500B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Semi Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Nbr de Couverts",
    "Valeur(s) de l’attribut 5": 12,
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 4,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "Consommation Eau",
    "Valeur(s) de l’attribut 7": "12 Litres",
    "Attribut 7 visible": 1,
    "Attribut 7 global": 0
  },
  {
    "ID": 1620,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 1372",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Lave-linge tout intégrable</li><li>Capacité de lavage : 8 Kg</li><li>15 programmes</li><li>Puissance acoustique : 53 dB(A)</li><li>Essorage de 0 à 1200 tr/min</li><li>Départ différé de 1 à 23 heures</li><li>Tambour en inox</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "LAVES LINGES",
    "Étiquettes": "1200 tr/min, 15 Programmes, 8 Kg, Blanc, Encastrable",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 1372.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 1372",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Capacité",
    "Valeur(s) de l’attribut 4": "8 Kg",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Essorage",
    "Valeur(s) de l’attribut 5": "1200 tr/min",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "Nbr de Programmes",
    "Valeur(s) de l’attribut 6": 15,
    "Attribut 6 visible": 1,
    "Attribut 6 global": 0,
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1621,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 6400",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Réfrigérateur pose libre Inox foncé</li><li>4 Portes</li><li>Congélateur inférieur</li><li>84 cm</li><li>Afficheur</li><li>Fontaine à eau</li><li>Technologie ionique</li><li>Multi-refroidissement No Frost</li><li>Classe énergétique A+</li><li>Volume: 620 litres</li><li>Classe climatique : ST</li><li>Clayettes en verre</li><li>Puissance acoustique : 41 dB(A)</li><li>Dimensions : 840X690X1790</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "84 Cm, Dark Inox, No-Frost, Pose Libre",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 6400.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 6400",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Dark Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Pose Libre",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "84 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "No-Frost",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1622,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 6300",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Réfrigérateur pose libre Inox</li><li>4 Portes</li><li>Congélateur inférieur</li><li>84 cm</li><li>Afficheur</li><li>Fontaine à eau</li><li>Technologie ionique</li><li>Multi-refroidissement No Frost</li><li>Classe énergétique A+</li><li>Volume: 620 litres</li><li>Classe climatique : ST</li><li>Clayettes en verre</li><li>Puissance acoustique : 41 dB(A)</li><li>Dimensions : 840X690X1790</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "84 Cm, INOX, No-Frost, Pose Libre",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 6300.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 6300",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Pose Libre",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "84 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "No-Frost",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1623,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 3000",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Réfrigérateur intégrable</li><li>Froid Dynamique</li><li>5 Clayettes en verre réglables</li><li>5 Balconnets</li><li>2 Bacs à légumes</li><li>Porte réversible</li><li>Eclairage LED</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "60 Cm, Blanc, Encastrable, Froid Dynamique",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 3000.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 3000",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "Froid Dynamique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1624,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 3100",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Congélateur intégrable No Frost</li><li>Tiroirs de congélation transparents</li><li>Volume: 220 litres</li><li>Classe climatique: SN-T</li><li>42 dB (A) Niveau sonore</li><li>Porte réversible</li><li>Eclairage LED</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "60 Cm, Blanc, Encastrable, No-Frost",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 3100.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 3100",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "No-Frost",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1625,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 3200",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Combiné intégrable</li><li>Totalement No Frost</li><li>Classe climatique : ST</li><li>Clayettes en verre</li><li>Puissance acoustique : 39 dB(A)</li><li>3 Tiroirs de congélation</li><li>Afficheur électronique</li><li>Portes Réversibles</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "60 Cm, Blanc, Encastrable, No-Frost",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 3200.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 3200",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "No-Frost",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1626,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 3600",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Combiné intégrable</li><li>Compartiment réfrigérateur : froid dynamique</li><li>Compartiment congélateur : No frost</li><li>Classe climatique : ST</li><li>Clayettes en verre</li><li>Puissance acoustique : 39 dB(A)</li><li>3 Tiroirs de congélation</li><li>Portes Réversibles</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "60 Cm, Blanc, Encastrable, Froid Dynamique",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 3600.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 3600",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "Froid Dynamique",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1627,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 585",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "<ul><li>Réfrigérateur sous plan intégrable</li><li>Clayettes en verre</li><li>1 Bac à légumes</li><li>Porte Réversible</li></ul>",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "RÉFRIGÉRATEURS",
    "Étiquettes": "60 Cm, Blanc, Encastrable, Manuel",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 585.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 585",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Installation",
    "Valeur(s) de l’attribut 3": "Encastrable",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Taille",
    "Valeur(s) de l’attribut 4": "60 Cm",
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Dégivrage",
    "Valeur(s) de l’attribut 5": "Manuel",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1628,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 116B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 116B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 116B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1629,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 116G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 116G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 116G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1630,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 116A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 116A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 116A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1631,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 116S",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Champagne",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 116S.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 116S",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Champagne",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1632,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 116B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 116B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 116B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1633,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 116G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 116G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 116G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1634,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 116A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 116G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 116A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1635,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 116W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 116W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 116W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1636,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 116B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 116B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 116B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1637,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 116G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 116G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 116G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1638,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 116A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Avèna",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 116A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 116A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Avèna",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1639,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 116W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 116W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 116W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1640,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 80B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 79 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 80B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 80B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "79 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1641,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 80G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 79 Cm, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 80G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 80G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "79 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1642,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 80A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 79 Cm, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 80A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 80A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "79 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1643,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 80W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 79 Cm, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 80W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 80W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "79 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1644,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 40B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 41 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 40B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 40B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "41 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1645,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 40G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 41 Cm, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 40G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 40G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "41 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1646,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 40A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 41 Cm, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 40A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 40A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "41 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1647,
    "Type": "simple",
    "UGS": "",
    "Nom": "QUADRA 40W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 41 Cm, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/QUADRA 40W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "QUADRA 40W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "41 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1648,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 100B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 100 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 100 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 100B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "100 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1649,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 100G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 100 Cm, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 100 G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 100G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "100 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1650,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 100A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 100 Cm, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 100 A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 100A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "100 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1651,
    "Type": "simple",
    "UGS": "",
    "Nom": "FILO 100W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 100 Cm, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FILO 100 W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FILO 100W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "100 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1652,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 90G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 100 Cm, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 90G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 90G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "100 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1653,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 90B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "2 Bacs, 86 Cm, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 90B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 90B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1654,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 86B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 86B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 86B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1655,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 90S",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "2 Bacs, 86 Cm, Champagne",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 90S.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 90S",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Champagne",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1656,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 90W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "2 Bacs, 86 Cm, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 90W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 90W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1658,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 86G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 86G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 86G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1659,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 86A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 86A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 86A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1660,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 86S",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Champagne",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 86S.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 86S",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Champagne",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1661,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 86B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 86B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 86B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1662,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 86G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 86G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 86G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1663,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 86A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Avèna",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 86A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 86A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Avèna",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1664,
    "Type": "simple",
    "UGS": "",
    "Nom": "F 86W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/F 86W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "F 86W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1665,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 76B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 76 Cm, Installation sous plan, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 76 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 76B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "76 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1666,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 76G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 76 Cm, Gris, Installation sous plan",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 76 G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 76G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "76 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1668,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 76A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 76 Cm, Grège, Installation sous plan",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 76 A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 76A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "76 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1669,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 76W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 76 Cm, Blanc, Installation sous plan",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 76 W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 76W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "76 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1670,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 56B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 56 Cm, Installation sous plan, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 56 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 56B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "56 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1671,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 56G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 56 Cm, Gris, Installation sous plan",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 56 G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 56G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "56 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1672,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 56A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 56 Cm, Grège, Installation sous plan",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 56 A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 56A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "56 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1673,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMILE 56W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 56 Cm, Blanc, Installation sous plan",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMILE 56 W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMILE 56W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "56 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Oui",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1674,
    "Type": "simple",
    "UGS": "",
    "Nom": "FH 1162",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "116 Cm, 2 Bacs, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FH 1162.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FH 1162",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "116 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 2,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1675,
    "Type": "simple",
    "UGS": "",
    "Nom": "FH 861",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "ÉVIERS",
    "Étiquettes": "1 Bac, 86 Cm, INOX",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/FH 861.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "FH 861",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Inox",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Taille",
    "Valeur(s) de l’attribut 3": "86 Cm",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "Nbr Bacs",
    "Valeur(s) de l’attribut 4": 1,
    "Attribut 4 visible": 1,
    "Attribut 4 global": 0,
    "Nom de l’attribut 5": "Installation Sous-Plan",
    "Valeur(s) de l’attribut 5": "Non",
    "Attribut 5 visible": 1,
    "Attribut 5 global": 0,
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1676,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 559 CR",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Chromé",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 559 CR.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 559 CR",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Chromé",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1677,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1090 CR",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Chromé",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1090 CR.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1090 CR",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Chromé",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1678,
    "Type": "simple",
    "UGS": "",
    "Nom": "JASMINE",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Chromé",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/JASMINE.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "JASMINE",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Chromé",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1679,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 110 CR",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Chromé",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 110 CR.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 110 CR",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Chromé",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1680,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 110 B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Noir",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 110 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 110 B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1681,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 110 A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Grège",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 110 A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 110 A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1682,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 110 G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Gris",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 110 G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 110 G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1683,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 110 W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Blanc",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 110 W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 110 W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1684,
    "Type": "simple",
    "UGS": "",
    "Nom": "SMART 110 S",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avec Douchette, Champagne",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/SMART 110 S.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "SMART 110 S",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Champagne",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Oui",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1685,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 7730 CR",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Chromé, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 7730 CR.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 7730 CR",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Chromé",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1686,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 7730 B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Noir, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 7730 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 7730 B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1687,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 7730 A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Grège, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 7730 A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 7730 A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1688,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 7730 G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Gris, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 7730 G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 7730 G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1689,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 7730 W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Blanc, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 7730 W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 7730 W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1690,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 CR",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Chromé, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 CR.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 CR",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Chromé",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1691,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 B",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Noir, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 B.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 B",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Noir",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1692,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 A",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Grège, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 A.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 A",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Grège",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1693,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 AV",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Avèna, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 AV.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 AV",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Avèna",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1694,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 G",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Gris, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 G.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 G",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Gris",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1695,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 W",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Blanc, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 W.jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 W",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Blanc",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  },
  {
    "ID": 1696,
    "Type": "simple",
    "UGS": "",
    "Nom": "AP 1600 S",
    "Publié": 1,
    "Mis en avant ?": 0,
    "Visibilité dans le catalogue": "visible",
    "Description courte": "",
    "Description": "",
    "Date de début de promo": "",
    "Date de fin de promo": "",
    "État de la TVA": "taxable",
    "Classe de TVA": "",
    "En stock ?": 1,
    "Stock": "",
    "Montant de stock faible": "",
    "Autoriser les commandes de produits en rupture ?": 0,
    "Vendre individuellement ?": 0,
    "Poids (kg)": "",
    "Longueur (cm)": "",
    "Largeur (cm)": "",
    "Hauteur (cm)": "",
    "Autoriser les avis clients ?": 1,
    "Note de commande": "",
    "Tarif promo": "",
    "Tarif régulier": "",
    "Catégories": "MITIGEURS",
    "Étiquettes": "Champagne, Sans Douchette",
    "Classe d’expédition": "",
    "Images": require('./../../assets/media/products/AP 1600 S (2).jpg'),
    "Limite de téléchargement": "",
    "Jours d’expiration du téléchargement": "",
    "Parent": "",
    "Groupes de produits": "",
    "Produits suggérés": "",
    "Ventes croisées": "",
    "URL externe": "",
    "Libellé du bouton": "",
    "Position": 0,
    "Nom de l’attribut 1": "Référence",
    "Valeur(s) de l’attribut 1": "AP 1600 S",
    "Attribut 1 visible": 1,
    "Attribut 1 global": 0,
    "Nom de l’attribut 2": "Couleur",
    "Valeur(s) de l’attribut 2": "Champagne",
    "Attribut 2 visible": 1,
    "Attribut 2 global": 0,
    "Nom de l’attribut 3": "Douchette",
    "Valeur(s) de l’attribut 3": "Non",
    "Attribut 3 visible": 1,
    "Attribut 3 global": 0,
    "Nom de l’attribut 4": "",
    "Valeur(s) de l’attribut 4": "",
    "Attribut 4 visible": "",
    "Attribut 4 global": "",
    "Nom de l’attribut 5": "",
    "Valeur(s) de l’attribut 5": "",
    "Attribut 5 visible": "",
    "Attribut 5 global": "",
    "Nom de l’attribut 6": "",
    "Valeur(s) de l’attribut 6": "",
    "Attribut 6 visible": "",
    "Attribut 6 global": "",
    "Nom de l’attribut 7": "",
    "Valeur(s) de l’attribut 7": "",
    "Attribut 7 visible": "",
    "Attribut 7 global": ""
  }
]