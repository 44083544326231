import React from "react"
import { MenuProps } from "../../shared/interfaces/menuItem.interface"
import { Link } from "react-router-dom"

const MegaMenu: React.FC<{ nav: MenuProps, showMegaMenu: boolean, setShowMegaMenu: any }> = ({ nav, showMegaMenu, setShowMegaMenu }) => {

  React.useEffect(()=>{
    window.addEventListener('click',(event: any)=>{
      let closestNav = event.target.closest('.nav-menu')
      let closestMegaMenu = event.target.closest('.megamenu')
      if(closestNav == null && closestMegaMenu == null) {
        setShowMegaMenu(false) 
      }
    })
  },[window])

  return (

    <div className={`megamenu absolute left-0 z-10 top-[70px] bg-[#444] shadow-lg w-full overflow-hidden px-32 transition-all duration-500 ease-in-out ${!showMegaMenu ? 'h-0' : 'h-96 pt-8 pb-4'}`}>
      <div className={`megamenu-wrapper grid grid-cols-5`}>
        {nav.children?.map((megamenu: MenuProps, megaKey: number) => {
          return (
            <div key={megaKey} className="megamenu-item group px-5">
              <div className="relative">
                <div className={"w-2 h-2 bg-focus-primary absolute -left-4 top-1 "+ (megaKey===0 ?'hidden':'')}></div>
                <span className="font-semibold uppercase cursor-pointer text-sm hover:text-focus-primary transition ease-in-out">{megamenu.name}</span>
              </div>
              {
                megamenu.type === "submenu" ?
                  (
                    <div className="subnav mt-2">
                      <div className="sabnav-wrapper">
                        <div className="sabnav-menu flex flex-col gap-1">
                          {megamenu.children?.map((subnav: MenuProps, subKey: number) => {
                            return (
                              <div key={subKey} className="subnav-item">
                                <Link onClick={() => setShowMegaMenu(false)} className="text-xs uppercase font-medium" to={subnav.path ? subnav.path : '#item'}>
                                  <span className="hover:text-focus-primary text-white transition ease-in-out">{subnav.name}</span>
                                </Link>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

const NavPlain: React.FC<{ navs: MenuProps[],setShowMegaMenu:any, showMegaMenu:boolean  }> = ({ navs = [],setShowMegaMenu,showMegaMenu }) => {

  

  return (
    <>
      {
        navs.map((nav: MenuProps, idx: number) => {
          return (
            <div key={idx} className="nav-item ml-7 first:ml-0 group">
              <Link onClick={nav.type === "megamenu" ? () => setShowMegaMenu(!showMegaMenu) : () => setShowMegaMenu(false)} className="text-xs p-3 uppercase font-semibold" to={nav.path ? nav.path : 'javascript:void(0)'}>
                <span className={(showMegaMenu && nav.type === "megamenu" ? 'text-focus-primary' : 'text-white') + " group-hover:text-focus-primary  transition ease-in-out"}>{nav.name}</span>
              </Link>
              {nav.type === "megamenu" ? <MegaMenu nav={nav} showMegaMenu={showMegaMenu} setShowMegaMenu={setShowMegaMenu} /> : null}
            </div>
          )
        })
      }
    </>
  )
}

const NavigationComponent: React.FC<{ navigation: MenuProps[], className?: string, setShowMegaMenu: any, showMegaMenu:boolean }> = ({ navigation = [], className = "", showMegaMenu, setShowMegaMenu }) => {

  

  return (
    <div className={"nav " + className}>
      <div className="nav-wrapper justify-end">
        <div className="nav-menu flex">
          <NavPlain setShowMegaMenu={setShowMegaMenu} showMegaMenu={showMegaMenu} navs={navigation} />
        </div>
      </div>
    </div>
  )

}

export default NavigationComponent