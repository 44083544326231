import { Link, useParams } from "react-router-dom"
import { RiArrowLeftSLine } from "react-icons/ri";
import DividerComponent from "../shared/components/divider.component";
import React from "react";
import { productList } from "../shared/data/productsList.data";
import FileViewer from "../shared/components/fileViewer.component";
import { useNavigate } from "react-router-dom";

const ProductDetailPage: React.FC = () => {

  let { id } = useParams();
  let navigate = useNavigate();

  const [product, setProduct]: any = React.useState(null)
  const [loading, setLoading]: any = React.useState(true)

  const fetchProduct = async (id: any) => {
    let index = productList.findIndex((pr: any) => pr['ID'] == id)
    let product: any = await productList[index]
    setProduct(product)
  }

  React.useEffect(() => {
    fetchProduct(id)
  }, [id])

  React.useEffect(() => {
    if (product) {
      setLoading(false)
    }
  }, [product])

  const PageActions = () => {
    return (
      <div className="action">
        <div className="actions-wrapper">
          <div className="flex justify-between">
            <Link to="#p" onClick={() => navigate(-1)} className="flex items-center gap-1">
              <RiArrowLeftSLine className="fill-[#444]" />
              <span className="!text-[#444] uppercase text-sm font-semibold">
                Retour
              </span>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  const AlterCard: React.FC<{ label: string, value: string }> = ({ label, value }) => {
    return (
      <div className="flex justify-between text-sm">
        <p className="text-[#444]">{label}</p>
        <p className="text-[#444] font-semibold">{value}</p>
      </div>
    )
  }

  return (
    <div className="detail-page xl:container xl:mx-auto">
      {
        !loading ?
          <div className="page-wrapper">

            {/* <span>
              {JSON.stringify(product)}
            </span> */}

            <section className="bg-white p-4 px-5 mb-16">
              <div className="page-header mb-10">
                <div className="header-wrapper">
                  <PageActions />
                </div>
              </div>
              <div className="page-body container">
                <div className="body-wrapper">
                  <div className="grid grid-cols-1 md:grid-cols-12 gap-5">
                    <div className="detail order-2 md:order-1 md:col-start-2 md:col-span-4 flex flex-col gap-10">
                      <div className="product-title flex flex-col gap-2">
                        <h3 className="text-focus-primary uppercase text-xl font-semibold">{product['Nom']}</h3>
                        <h6 className="text-[#222] uppercase font-semibold text-sm">{product['Catégories']}</h6>
                      </div>
                      <div className="product-detail !text-[#333]">
                        <p className="!text-[#333] text-justify text-sm font-medium leading-6" style={{ color: "#333" }} dangerouslySetInnerHTML={{ __html: product['Description courte'] }}>
                          {/* SOFIA PRO 48 DUAL FUEL represents the excellence of FULGOR MILANO, it is synonymous with Italian style, mastery in product development and
                      accuracy in workmanship. Thanks to the cooktop equipped with 6 Crescendo Dual brass gas burners, the Teppan Yaki plate and the two high-performance
                      pyrolytic multifunction ovens, this version proves to be a flexible tool, capable of enhancing the creative aptitudes of kitchen professionals.
                      The standard accessories of the main oven include a fully extractable telescopic rack, two flat racks, a MEAT probe, pizza set (round rack, peel and pizza
                      stone), enameled tray with anti-splash and tray for AirFry. The support oven has a fully extractable telescopic rack, two flat racks and a stainless-steel tray
                      for AirFry. */}

                        </p>
                      </div>
                      <div className="w-full h-[1px] bg-[#333]"></div>
                      <div className="product-alter">
                        <div>
                          <AlterCard label={product['Nom de l’attribut 2']} value={product['Valeur(s) de l’attribut 2']} />
                          <AlterCard label={product['Nom de l’attribut 3']} value={product['Valeur(s) de l’attribut 3']} />
                          <AlterCard label={product['Nom de l’attribut 4']} value={product['Valeur(s) de l’attribut 4']} />
                          <AlterCard label={product['Nom de l’attribut 5']} value={product['Valeur(s) de l’attribut 5']} />
                          <AlterCard label={product['Nom de l’attribut 6']} value={product['Valeur(s) de l’attribut 6']} />
                          <AlterCard label={product['Nom de l’attribut 7']} value={product['Valeur(s) de l’attribut 7']} />
                        </div>
                      </div>
                    </div>
                    <div className="md:col-start-7 order-1 md:order-2 md:col-span-5">
                      <img className="h-full max-h-[75vh]" src={product['Images']} alt="img-alt" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="features-section mb-16">
              <div className="section-wrapper">
                <div className="content px-8">
                  <DividerComponent title="Features" />
                  <div className="bg-white grid grid-cols-12 py-10 px-8 gap-5">
                    {
                      [...Array(10)].map((d: any, idx: number) => {
                        return (
                          <div key={idx} className="featrue col-span-3">
                            <div className="featrue-wrapper text-[#333] flex text-xs items-center gap-2 uppercase font-medium">
                              <img className="w-10 h-10" src="https://www.fulgor-milano.com/sites/default/files/styles/taxonomy_features_40x/public/2023-04/Creactive%20Cooking%20System_0.png?itok=D0qNRU-W" alt="icon-alt" />
                              <p className="text-inherit">CREACTIVE EXTENDED</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </section> */}

            {/* <section className="family-story-section mb-16">
              <div className="section-wraper">
                <div className="content px-8">
                  <DividerComponent title="SPECIFICATIONS" />
                  <div className="bg-white py-10 px-8">

                  </div>
                </div>
              </div>
            </section> */}

            {product['Catégories'] != "MITIGEURS" ? <section className="family-story-section mb-16">
              <div className="section-wraper">
                <div className="content px-2 md:px-8">
                  <DividerComponent title="Fiche technique" />
                  <div className="bg-white md:py-10 md:px-8">
                    <FileViewer product={product['Nom']} />

                  </div>
                </div>
              </div>
            </section> : null}

            {/* <section className="family-story-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="FEATURED PRODUCTS"/>
              <div className="bg-white py-10 px-8">

              </div>
            </div>
          </div>
        </section> */}
          </div> : <div className="h-screen flex justify-center items-center">
            <p>Chargement ...</p>
          </div>
      }
    </div>
  )
}

export default ProductDetailPage