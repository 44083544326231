import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";


const NotFoundPage = () => {

  const error:any = useRouteError();
  console.error(error);
  
  return (
    <div className="notfound-page flex justify-center items-center gap-5 flex-col w-screen h-screen">
      <h1 className="text-4xl uppercase font-bold">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>

      <Link to={"/"} >Go back</Link>
    </div>
  )
}

export default NotFoundPage