

const DividerComponent: React.FC<{ title?: string }> = ({ title = "" }) => {
  return (
    <div className="relative flex justify-start h-4 mb-5">
      <span className="w-full h-[1px] bg-white absolute top-2 left-0"></span>
      <div className={`relative text-xs font-semibold uppercase bg-[#333] ${title?'pr-5':''} w-fit`}> {title} </div>
    </div>
  )
}

export default DividerComponent