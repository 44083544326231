import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import eviers from "./../../assets/media/images/eviers.jpg"
import froid from "./../../assets/media/images/froid.jpg"
import hottes from "./../../assets/media/images/hottes.jpg"
import linges from "./../../assets/media/images/lave-linges.jpg"
import vaisselles from "./../../assets/media/images/lave-vaisselles.jpg"
import mitigeurs from "./../../assets/media/images/mitigeurs.jpg"
import plaques from "./../../assets/media/images/plaques.jpg"

const SwiperComponent: React.FC = () => {

  const SwiperSlides:React.FC<{src?:string}> = ({src="https://placehold.co/2048x820"}) => {
    return (
      <div className="swiper-slides h-full max-h-[30vh] md:max-h-[50vh] xl:max-h-[90vh] overflow-hidden">
        <img className="h-full w-full object-cover" src={src} alt="img" />
      </div>
    )
  }

  return (
    <Swiper
      spaceBetween={0}
      loop={true}
      slidesPerView={1}
      autoHeight={false}
      autoplay={{
        delay:5000
      }}
      modules={[Pagination, Navigation, Autoplay]}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      className="max-h-[30vh] md:max-h-[50vh] xl:max-h-[90vh]" >
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN1.1.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN9.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN1.2.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN1.4.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN6.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN1.5.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN1.9.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN1.12.jpg')} />
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlides src={require('./../../assets/media/images/KITCHEN5.jpg')} />
      </SwiperSlide>
    </Swiper>
  )
}

export default SwiperComponent