
import { Link } from "react-router-dom";
import React from "react";

const LinkBoxComponent: React.FC<{ link?: string, src?: string,title?:string }> = ({ link = "#item", src = "https://placehold.co/512", title = "" }) => {
  return (
    <Link className="link-box" to={link}>
      <div className="box-wrapper relative group">
        <div className="image-space overflow-hidden">
          <img className="group-hover:scale-150 duration-300 w-full object-cover min-h-[300px] max-h-[400px]" src={src} alt="alt img" />
        </div>
        <span className="bg-[#222] group-hover:text-focus-primary px-4 font-semibold text-sm py-5 w-full absolute left-0 bottom-0 right-0 text-center uppercase">{title}</span>
      </div>
    </Link>
  )
}

export default LinkBoxComponent