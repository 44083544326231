import { Link } from "react-router-dom"


const ProductCard:React.FC<{idx:number, data:any}> = ({idx,data}) => {
  return (
    <Link className="group" key={idx} to={"/products/"+data['ID']}>
      <div className="product-component bg-[#222] group-hover:bg-focus-primary">
        <div className="component-wrapper">
          <div className="product-img max-h-70 bg-white p-10 overflow-hidden">
            <img className="h-full max-h-70 object-cover group-hover:scale-125 duration-300" src={data['Images']} onError={({ currentTarget })=>{currentTarget.src = "https://placehold.co/512"}} alt="product-img" />
          </div>
          <div className="product-body p-4 text-sm flex flex-col items-center justify-center">
            <div className="flex flex-col items-center uppercase">
              <h4>{data['Catégories']}</h4>
              <p className="font-semibold text-xs text-center">{data['Étiquettes']}</p>
            </div>
            <div className="w-full h-[1px] bg-white my-2"></div>
            <p>{data['Nom']}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ProductCard