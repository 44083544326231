import { Checkbox, Form, GetProp, Input, Radio, Tooltip } from "antd"
import React from "react"
import { productList } from "../data/productsList.data";
import { RiSearchLine, RiCheckFill, RiRefreshLine } from "react-icons/ri";


const FilteCheckboxCardComponent: React.FC<{ filterName: string, filters: any, setFilters: any, title: string, attribute: string, productsList: Array<any> }> = ({ filterName, filters, setFilters, attribute, title, productsList }) => {

  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setFilters({
      ...filters,
      [filterName]: checkedValues
    })
  };

  const productFilter = (products: Array<any>, attr: string) => {

    const results: any = [];

    products.forEach(item => {
      for (let i = 1; i <= 7; i++) {
        if (item[`Nom de l’attribut ${i}`] === attr) {
          results.push(item[`Valeur(s) de l’attribut ${i}`]);
          break;
        }
      }
    });
    let uniqueItems = Array.from(new Set(results));
    return uniqueItems

  }

  const filteredItems = productFilter(productsList, attribute);
  if (filteredItems.length === 0) return null;

  return (
    <section className="category-filter">
      <div className="wrapper bg-[#555] uppercase font-semibold">
        <div className="flex justify-between items-center py-2 px-5">
          <div className="text-xs">{title}</div>
          <RiRefreshLine className="cursor-pointer" onClick={() => setFilters({ ...filters, [filterName]: [] })} />
        </div>
        <Checkbox.Group onChange={onChange} value={filters[filterName]} className="px-10 py-5 m-0 grid grid-cols-2 gap-2 bg-[#444]">
          {filteredItems.map((value: any, idx: number) => (
            <li key={idx} className="text-xs font-normal cursor-pointer relative">
              <div className="flex gap-2 w-full items-center">
                <Checkbox className="checkbox-custom" id={value} value={value} />
                <label className={`text-xs cursor-pointer ${filters[filterName]?.includes(value) ? "text-focus-primary font-semibold" : ""}`} htmlFor={value}>{value}</label>
              </div>
            </li>
          ))}
        </Checkbox.Group>
      </div>
    </section>
  )
}

const FilteRadioCardComponent: React.FC<{ filterName: string, filters: any, setFilters: any, title: string, attribute: string, productsList: Array<any> }> = ({ filterName, filters, setFilters, attribute, title, productsList }) => {

  const onChange: GetProp<typeof Radio.Group, 'onChange'> = (checkedValues) => {
    
    setFilters({
      ...filters,
      [filterName]: checkedValues.target.value,
      colors: [],
      types: [],
      functions: [],
      grills: [],
      lights: [],
      Thermocouples: [],
      volumes: [],
      sizes: []
    })
  };

  const productFilter = (products: Array<any>, attr: string) => {

    const attributeValues = products.map(product => product[attr])
    let nonEmptyItems = attributeValues.filter(item => item !== '');
    let uniqueItems = Array.from(new Set(nonEmptyItems));

    return uniqueItems
  }

  return (
    <section className="category-filter">
      <div className="wrapper bg-[#555] uppercase font-semibold">
        <div className="flex justify-between items-center  py-2 px-5">
          <div className="text-xs">{title}</div>
          <Tooltip title="Réinitialiser tous les filtres">
            <RiRefreshLine className="cursor-pointer" onClick={() => setFilters({
              ...filters,
              colors: [],
              types: [],
              categories: [],
              functions: [],
              bacs: [],
              grills: [],
              lights: [],
              Thermocouples: [],
              volumes: [],
              sizes: []
            })} />
          </Tooltip>
        </div>
        <Radio.Group onChange={onChange} value={filters[filterName]} className="px-10 py-5 m-0 flex flex-col gap-2 bg-[#444]">
          {
            productFilter(productsList, attribute).map((value: any, idx: number) => {
              return (
                <li key={idx} className="text-xs font-normal cursor-pointer relative">
                  <div className="flex justify-between w-full items-center">
                    {/* <div>{value}</div> */}
                    <label className={"text-xs cursor-pointer " + (filters[filterName] === value ? "text-focus-primary font-semibold" : "")} htmlFor={value}>{value}</label>
                    <Radio id={value} value={value} className="absolute right-0 top-0 -z-10" />
                    {value === filters[filterName] ? <RiCheckFill className="fill-focus-primary text-2xl" /> : null}
                  </div>
                </li>
              )
            })
          }
        </Radio.Group>
      </div>
    </section>
  )
}

const FilterComponent: React.FC<{ filters: any, setFilters: any, productsList: Array<any> }> = ({ filters, setFilters, productsList }) => {

  return (
    <div className="filter">
      <div className="filter-wrapper">

        <Form className="gap-2 flex flex-col">

          <Form.Item className="m-0 bg-[#555] relative">
            <Input onChange={(e) => setFilters({ ...filters, reference: e.target.value })} value={filters.reference} className="bg-[#555] pr-5 !outline-none placeholder:text-white placeholder:font-montserrat placeholder:font-semibold placeholder:text-xs placeholder:uppercase placeholder:px-2 focus:!outline-none !shadow-none border-none !text-white focus:bg-[#555] hover:bg-[#555] rounded-none" size="middle" placeholder="PAR CODE D'IDENTIFICATION DU PRODUIT" />
            <div className="absolute right-0 top-0 bottom-0 flex justify-center items-center p-2">
              <RiSearchLine className="fill-focus-primary" />
            </div>
          </Form.Item>

          <Form.Item className="m-0 !text-white bg-[#555] px-5 uppercase font-semibold" >
            <div className="flex justify-between w-full items-center">
              <div className="text-xs">Nouvelles Arrivées</div>
              <Checkbox className="checkbox-custom" />
            </div>
          </Form.Item>

          <FilteRadioCardComponent productsList={productList} filterName="categories" filters={filters} setFilters={setFilters} attribute="Catégories" title="Catégories de produit" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="colors" filters={filters} setFilters={setFilters} attribute="Couleur" title="Couleurs" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="sizes" filters={filters} setFilters={setFilters} attribute="Taille" title="Tailles" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="types" filters={filters} setFilters={setFilters} attribute="Type" title="Types" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="volumes" filters={filters} setFilters={setFilters} attribute="Volume" title="Volumes" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="bacs" filters={filters} setFilters={setFilters} attribute="Nbr Bacs" title="Nbr Bacs" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="grills" filters={filters} setFilters={setFilters} attribute="Grill" title="Grill" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="functions" filters={filters} setFilters={setFilters} attribute="Nbr de fonctions" title="Nbr fonctions" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="lights" filters={filters} setFilters={setFilters} attribute="Nbr Feux" title="Nbr Feux" />
          <FilteCheckboxCardComponent productsList={productsList} filterName="Thermocouples" filters={filters} setFilters={setFilters} attribute="Avec Thermocouple" title="Avec Thermocouple" />

        </Form>


      </div>
    </div>
  )
}

export default FilterComponent