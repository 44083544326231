import DividerComponent from "../shared/components/divider.component"
import FileViewer from "../shared/components/fileViewer.component"
import PageHeaderComponent from "../shared/components/pageHeader.component"
import img from "./../assets/media/images/KITCHEN2.jpg"

const CatalogPage: React.FC = () => {
  return (
    <div className="catalog-page">
      <div className="page-wrapper">

        <PageHeaderComponent src={img} title="Notre catalogue" />

        <section className="catalog-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Catalogue 2024" />
              <FileViewer product={'catalog_2024'} />
            </div>
          </div>
        </section>

      </div>
    </div>
  )
}

export default CatalogPage