import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home.page";
import NotFoundPage from "../shared/components/notFound.page";
import MainLayout from "../layout/main.layout";
import ProductPage from "../pages/product.page";
import CatalogPage from "../pages/catalog.page";
import SupportPage from "../pages/support.page";
import AboutPage from "../pages/about.page";
import DistributorPage from "../pages/distributor.page";
import ProductDetailPage from "../pages/productDetail.page";

const router = createBrowserRouter([
  {
    path:'',
    element: <MainLayout/>,
    errorElement: <NotFoundPage />,
    children:[
      {
        index:true,
        caseSensitive:true,
        element: <HomePage />
      },
      {
        path:'products',
        caseSensitive:true,
        children:[
          {
            index:true,
            element: <ProductPage />
          },
          {
            path:':id',
            element: <ProductDetailPage />
          }
        ]
      },
      {
        path:'catalog',
        caseSensitive:true,
        element: <CatalogPage />
      },
      {
        path:'distributor',
        caseSensitive:true,
        element: <DistributorPage />
      },
      {
        path:'support',
        caseSensitive:true,
        element: <SupportPage />
      },
      {
        path:'about',
        caseSensitive:true,
        element: <AboutPage />
      }
    ]
  },
]);

export default router;