import DividerComponent from "../shared/components/divider.component"
import PageHeaderComponent from "../shared/components/pageHeader.component"
import img from "./../assets/media/images/KITCHEN5.jpg"

const AboutPage: React.FC = () => {
  return (
    <div className="about-page">
      <div className="page-wrapper">
        <PageHeaderComponent src={img} title="Qui somme nous" />

        <section className="product-line-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="A propos de la société" />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 bg-[#444] p-6">
                <div className="title col-span-1 md:col-span-2">
                  <h1 className="text-center uppercase font-bold text-2xl">Bienvenue chez FOCUS : L'Art de la Cuisine Depuis 2000</h1>
                </div>
                <div>
                  <p className="text-justify">Chez FOCUS, nous sommes passionnés par la cuisine. Depuis notre fondation en l'an 2000, nous avons consacré notre expertise
                    à créer des solutions d'électroménager exceptionnelles pour chaque foyer. Notre engagement envers l'excellence se reflète dans
                    chaque produit que nous proposons.
                  </p>
                </div>
                <div>
                  <p className="text-justify">Explorez Notre Collection Complète pour une Cuisine Exceptionnelle
                    Découvrez notre vaste gamme de produits conçus pour répondre à tous vos besoins culinaires :</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product-line-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Notre collection" />
              <ul className="bg-[#444] p-6 flex flex-col gap-5">
                <li>
                  <p><span className="uppercase font-bold">Fours</span> : Nos fours combinent style et fonctionnalité pour une cuisson parfaite à chaque fois.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Plaques de cuisson</span> : Des plaques de cuisson innovantes offrant une précision de température inégalée.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Hottes</span> : Nos hottes élégantes et performantes gardent votre cuisine fraîche et propre.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Micro-ondes</span> : Simplifiez la préparation de vos repas avec nos micro-ondes intuitifs.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Lave-vaisselle</span> : Profitez de vaisselle éclatante de propreté avec nos lave-vaisselle éco-énergétiques.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Lave-linge</span> : Nos lave-linge robustes prennent soin de vos vêtements tout en préservant l'environnement.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Réfrigérateurs et congélateurs</span> : Gardez vos aliments frais avec nos réfrigérateurs et congélateurs spacieux.</p>
                </li>
                <li>
                  <p><span className="uppercase font-bold">Éviers et mitigeurs</span> : Ajoutez une touche de style à votre cuisine avec nos éviers et mitigeurs élégants.</p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="product-line-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                <div className="col-span-1">
                  <DividerComponent title="Notre Engagement envers la Qualité et l'Innovation" />
                  <p className="text-justify">Chez FOCUS, la qualité est notre priorité absolue. Nous nous efforçons de dépasser les attentes en matière de performance, de durabilité et de sécurité. Chaque produit est le fruit d'une recherche constante de l'innovation, pour vous offrir une expérience culinaire exceptionnelle à chaque instant.
                  </p>
                </div>
                <div className="col-span-1">
                  <DividerComponent title="Découvrez la Différence FOCUS" />
                  <p className="text-justify">Transformez votre cuisine en un espace où la créativité culinaire prend vie avec FOCUS. Explorez notre collection dès aujourd'hui et laissez-nous vous accompagner dans la création de souvenirs inoubliables autour de la table.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  )
}

export default AboutPage