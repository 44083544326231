import { Menu } from "antd"
import React from "react"
import { MenuProps } from "../../shared/interfaces/menuItem.interface"
import { Link } from "react-router-dom"

const SideMenuComponent: React.FC<{ navigation: MenuProps[], className?: string, setIsOpen: any }> = ({ navigation = [], className = "", setIsOpen }) => {

  return (
    <div className="side-menu">
      <Menu className="text-black" mode="inline">
        {navigation.map((nav: MenuProps, index: number) => {
          return (
            <>
              {
                nav.type === "plain" ?
                  <Menu.Item
                    key={"m" + index}
                    className={(nav.mobile ? "" : "!hidden") + " bg-[#f8f8f8] !rounded-none !m-0 !p-8 text-xl"}>
                    <Link onClick={() => setIsOpen(false)} to={nav.path ? nav.path : "#item"}>
                      <div className="text-black text-xs">{nav.name}</div>
                    </Link>
                  </Menu.Item>
                  :
                  <Menu.SubMenu
                    key={"m" + index}
                    className={"bg-[#f8f8f8] !rounded-none px-1 py-2 text-xl " + (nav.mobile ? "" : "!hidden")}
                    title={<div className="text-black text-xs">{nav.name}</div>}>
                    {nav.children?.map((subnav: MenuProps, sIndex: number) => {
                      return (
                        <>
                          {
                            subnav.type === "submenu" ?
                              <Menu.SubMenu
                                key={"m" + index + "sm" + sIndex}
                                className={"bg-[#f8f8f8] !rounded-none px-1 py-2 text-xl " + (subnav.mobile ? "" : "!hidden")}
                                title={<div className="text-black text-xs">{subnav.name}</div>}>
                                {subnav.children?.map((subnavItem: MenuProps, xsIndex: number) => {
                                  return (
                                    <Menu.Item
                                      key={"m" + index + "sm" + sIndex + "xsm" + xsIndex}
                                      className={" bg-[#ddd] !rounded-none !m-0 !p-8 " + (subnavItem.mobile ? "" : "!hidden")}>
                                      <Link onClick={() => setIsOpen(false)} to={subnavItem.path ? subnavItem.path : "#item"}>
                                        <div className="text-black text-xs">{subnavItem.name}</div>
                                      </Link>
                                    </Menu.Item>
                                  )
                                })}
                              </Menu.SubMenu>
                              :
                              <Menu.Item
                                key={"m" + index + "sm" + sIndex}
                                className={" bg-[#ddd] !rounded-none !m-0 !p-8 " + (subnav.mobile ? "" : "!hidden")}>
                                <Link onClick={() => setIsOpen(false)} to={subnav.path ? subnav.path : "#item"}>
                                  <div className="text-black text-xs">{subnav.name}</div>
                                </Link>
                              </Menu.Item>
                          }
                        </>
                      )
                    })}
                  </Menu.SubMenu>
              }
            </>
          )
        })}
      </Menu>
    </div>
  )

}

export default SideMenuComponent