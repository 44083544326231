import { Drawer, Input } from "antd"
import React from "react"
import { RiSearchLine } from "react-icons/ri";
import SideMenuComponent from "./sideMenu.component";
import { menuItemsData } from "../../shared/data/menuItems.data";

const DrawerComponent: React.FC<{ isOpen: boolean, setIsOpen: any }> = ({ isOpen = false, setIsOpen }) => {

  const DrawerHeader: React.FC = () => {
    return (
      <div className="w-full drawer-header flex gap-1 items-center bg-[#eee] px-2">
        <Input
          className="text-sm font-normal py-2"
          style={{ outline: 'none', border: 'none', boxShadow: 'none', background: 'transparent' }}
          placeholder="Search product by Name | Product code | EAN code" />
        <RiSearchLine className="fill-black flex justify-center items-center" />
      </div>
    )
  }

  return (
    <Drawer
      open={isOpen}
      maskClosable={true}
      closeIcon={<></>}
      closable={true}
      onClose={() => setIsOpen(false)}
      className="!bg-white"
      title={<></>}
      classNames={{ header: '!pl-0 !pr-6', body: '!p-0 !pt-2' }}
      width={'55%'}
      maskClassName="!bg-white !opacity-50">
      <SideMenuComponent navigation={menuItemsData} setIsOpen={(e: any) => setIsOpen(e)} />
    </Drawer>
  )
}

export default DrawerComponent