import { Button, Form, Input } from "antd"
import DividerComponent from "../shared/components/divider.component"
import PageHeaderComponent from "../shared/components/pageHeader.component"
import img from "./../assets/media/images/KITCHEN1.jpg"

const DistributorPage:React.FC = () => {

  var inputClass = " shadow-none !border-b rounded-none !border-[#444] !border-t-transparent !border-l-transparent !border-r-transparent "
  var inputClassHover = " hover:shadow-none hover:!border-b hover:rounded-none hover:!border-[#444] hover:!border-t-transparent hover:!border-l-transparent hover:!border-r-transparent "
  var inputClassFocus = " focus:shadow-none focus:!border-b focus:rounded-none focus:!border-[#444] focus:!border-t-transparent focus:!border-l-transparent focus:!border-r-transparent "

  return(
    <div className="distributor-page">
      <div className="page-wrapper">
        <PageHeaderComponent src={img} title="Devenir distributeur" />

        <section className="contact-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Contactez nous" />
              <div className="grid grid-cols-1 md:grid-cols-12 gap-5">
                <div className="col-span-1 md:col-span-3">
                  <div className="contact bg-[#444] p-5 h-full">
                    <div className="contact-wrapper">
                      <div className="contact-header text-sm uppercase">
                        <h3>Contact d'assistance</h3>
                        <div className="w-full h-[1px] bg-white my-4"></div>
                      </div>
                      <div className="contact-body py-4 text-sm flex flex-col gap-5">
                        <div className="contact-one flex flex-col gap-2">
                          <p>Adresse email :</p>
                          <p className="font-semibold">contact@focusline.tn</p>
                        </div>
                        {/* <div className="contact-two flex flex-col gap-2">
                          <p>Adresse email :</p>
                          <p className="font-semibold">omar@focusline.tn</p>
                        </div> */}
                        <div className="contact-three flex flex-col gap-2">
                          <p>Rejoignez notre réseau de partenaires et accédez à une gamme complète d’appareils électroménagers innovants et fiables. Bénéficiez de marges compétitives, d’un support dédié, et d’outils marketing performants. Contactez-nous dès aujourd’hui pour discuter des modalités de partenariat et commencer à offrir le meilleur à vos clients.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-9">
                  <div className="contact bg-white p-5">
                    <div className="contact-wrapper">

                      <Form layout="vertical" className="grid grid-cols-2 gap-5 !font-montserrat uppercase font-medium !text-xs">
                        <Form.Item label="Nom" className="text-xs">
                          <Input className={inputClass+inputClassHover+inputClassFocus} />
                        </Form.Item>
                        <Form.Item label="Prénom" className="text-xs !text-[#444]">
                          <Input className={inputClass+inputClassHover+inputClassFocus} />
                        </Form.Item>
                        <Form.Item label="Objet" className="col-span-2 text-xs">
                          <Input className={inputClass+inputClassHover+inputClassFocus} />
                        </Form.Item>
                        <Form.Item label="Tapez votre message" className="col-span-2 text-xs">
                          <Input.TextArea cols={10} rows={5} className={inputClass+inputClassHover+inputClassFocus} />
                        </Form.Item>

                        <Button className="bg-focus-primary text-white col-span-2 w-24 ml-auto hover:!bg-focus-primary hover:text-white" >Envoyer</Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="family-story-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="A propos de la société" />
              <a href="#item">
                <div className="grid grid-rows-1 lg:grid-cols-12 ">
                  <div className="lg:col-span-5">
                    <div className="image-space overflow-hidden">
                      <img className="w-full object-cover" src={"https://arsenal-bache.com/wp-content/uploads/2019/06/1-1110x630.jpeg"} alt="alt img" />
                    </div>
                  </div>
                  <div className="lg:col-span-7">
                    <div className="flex flex-col justify-center h-full gap-5 px-5 text-left lg:px-20 py-3 lg:py-6 bg-[#444]">
                      <h3 className="sm:text-2xl text-sm">Le quartier général</h3>
                      <div className="flex flex-col sm:text-sm text-xs md:leading-6 gap-4">
                        <p>Rue Ibn Jazzar, Sidi Daoud 2046 La Marsa Tunis - Tunisie</p>
                        <p>Phone: +216 31 40 38 18</p>
                        <p>Fax: +216 32 40 38 18</p>
                        <p>contact@focusline.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

      </div>
    </div>
  )
}

export default DistributorPage