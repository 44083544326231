import React from "react"
import { Dropdown, MenuProps, Space } from 'antd';
import { RiArrowDropDownLine } from "react-icons/ri";

const I81nComponent: React.FC = () => {

  const items: MenuProps['items'] = [
    {
      key: '1',
      title: 'FR',
      label: 'FR',
    },
    {
      key: '2',
      title: 'EN',
      label: 'EN',
    },
  ];

  return (
    <div className="lang">
      <div className="lang-wrapper">
        <Dropdown menu={{ items:items, className:'!bg-[#444]' }}>
          <a href="#i18n" onClick={(e) => e.preventDefault()}>
            <Space className="text-base">
              <div className="icon flex gap-0 items-center text-base">
                <span className="text-xs">FR</span>
                <RiArrowDropDownLine className="text-xl" />
              </div>
            </Space>
          </a>
        </Dropdown>
      </div>
    </div>
  )

}

export default I81nComponent