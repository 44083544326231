import React from "react"
import DividerComponent from "../shared/components/divider.component"
import FilterComponent from "../shared/components/filter.component"
import PageHeaderComponent from "../shared/components/pageHeader.component"
import ProductCard from "../shared/components/productCard.component"
import { productList } from "../shared/data/productsList.data"
import img from "./../assets/media/images/KITCHEN7.jpg"
import { useParams, useSearchParams } from "react-router-dom"

const ProductPage: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilters] = React.useState({
    reference: '',
    colors: [],
    types: [],
    categories: [],
    functions: [],
    grills: [],
    bacs: [],
    lights: [],
    Thermocouples: [],
    volumes: [],
    sizes: []
  }) as any

  const [filtredProducts, setFiltredProducts] = React.useState([]) as any
  const [categorisedProducts, seCategorisedProducts] = React.useState([]) as any

  const filterProducts = () => {

    const filteredProducts = productList.filter(product => {

      if (filter.categories.length > 0 && !filter.categories.includes(product["Catégories"])) {
        return false;
      }
      if (filter.colors.length > 0 && !filter.colors.includes(product["Valeur(s) de l’attribut 2"])) {
        return false;
      }
      if (filter.sizes.length > 0 && !filter.sizes.includes(product["Valeur(s) de l’attribut 3"]) && !filter.sizes.includes(product["Valeur(s) de l’attribut 4"]) && !filter.sizes.includes(product["Valeur(s) de l’attribut 5"])) {
        return false;
      }
      if (filter.types.length > 0 && !filter.types.includes(product["Valeur(s) de l’attribut 5"]) && !filter.types.includes(product["Valeur(s) de l’attribut 6"])) {
        return false;
      }
      if (filter.volumes.length > 0 && !filter.volumes.includes(product["Valeur(s) de l’attribut 3"]) && !filter.volumes.includes(product["Valeur(s) de l’attribut 4"])) {
        return false;
      }
      if (filter.grills.length > 0 && !filter.grills.includes(product["Valeur(s) de l’attribut 5"])) {
        return false;
      }
      if (filter.functions.length > 0 && !filter.functions.includes(product["Valeur(s) de l’attribut 3"])) {
        return false;
      }
      if (filter.bacs.length > 0 && !filter.bacs.includes(product["Valeur(s) de l’attribut 4"])) {
        return false;
      }
      if (filter.lights.length > 0 && !filter.lights.includes(product["Valeur(s) de l’attribut 3"]) && !filter.lights.includes(product["Valeur(s) de l’attribut 4"])) {
        return false;
      }
      if (filter.Thermocouples.length > 0 && !filter.Thermocouples.includes(product["Valeur(s) de l’attribut 3"])) {
        return false;
      }
      if (filter.reference != null && product['Nom'].toLowerCase().includes(filter.reference)) {
        return true;
      } else {
        return false
      }
      return true;
    });

    const categorisedProducts = productList.filter(product => {

      if (filter.categories.length > 0 && !filter.categories.includes(product["Catégories"])) {
        return false;
      }

      return true
    })

    setFiltredProducts(filteredProducts)
    seCategorisedProducts(categorisedProducts)
  }

  React.useEffect(() => {
    filterProducts()
    setSearchParams({category: filter.categories})  
  }, [filter])

  React.useEffect(() => {
    const category = searchParams.get("category");
    if (category) {
      setFilters({
        ...filter,
        categories: category
      })
    }
  }, [searchParams])

  return (
    <div className="product-page">
      <div className="page-wrapper">
        <PageHeaderComponent src={img} title="Nos produits" subtitle={searchParams.get("category")} />

        <section className="products-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Listes des produits" />
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-3 hidden lg:block">
                  <FilterComponent productsList={categorisedProducts} filters={filter} setFilters={setFilters} />
                </div>
                <div className="col-span-12 lg:col-span-9">
                  <div className="products">
                    <div className="grid grid-cols-12 gap-5">
                      {
                        filtredProducts?.map((data: any, index: number) => {
                          return (
                            <div key={index} className="col-span-12 md:col-span-6 lg:col-span-4">
                              <ProductCard data={data} idx={index} />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  )
}

export default ProductPage