import { MenuProps } from "../interfaces/menuItem.interface";


export const menuItemsData: MenuProps[] = [
  {
    name: 'Qui somme nous',
    mobile: true,
    type: "plain",
    path: "/about"
  },
  {
    name: 'Nos produits',
    mobile: true,
    path: null,
    type: 'megamenu',
    children: [
      {
        name: '',
        mobile: false,
        path: null,
      },
      {
        name: 'Nouveaux produits',
        mobile: true,
        path: null,
        type: 'submenu',
        children: [
          {
            name: 'Derniers arrivages',
            mobile: true,
            path: '/products'
          }
        ]
      },
      /* {
        name: 'Châines de production',
        path: null,
        mobile: true,
        type: 'submenu',
        children: [
          {
            name: 'Professionnel',
            mobile: true,
            path: null
          },
          {
            name: 'barbecue',
            mobile: true,
            path: null
          },
          {
            name: 'Grappe',
            mobile: true,
            path: null
          },
          {
            name: 'Technologie urbaine',
            mobile: true,
            path: null
          },
          {
            name: 'Plano',
            mobile: true,
            path: null
          },
          {
            name: 'Tiroirs',
            mobile: true,
            path: null
          },
          {
            name: 'Tables de cuisson',
            mobile: true,
            path: null
          },
          {
            name: 'Aspiration',
            mobile: true,
            path: null
          },
          {
            name: 'Nettoyage',
            mobile: true,
            path: null
          },
          {
            name: 'Réfrigération',
            mobile: true,
            path: null
          },
        ]
      }, */
      {
        name: 'Catégories de produit',
        mobile: true,
        path: null,
        type: 'submenu',
        children: [
          {
            name: 'Fours',
            mobile: true,
            path: "/products?category=FOURS",
            icon:require('./../../assets/media/icons/FOUR NEGATIF.png')
          },
          {
            name: 'Micro-ondes',
            mobile: true,
            path: "/products?category=MICRO-ONDES",
            icon:require('./../../assets/media/icons/MICRO-ONDES NEGATIF.png')
          },
          {
            name: 'Plaques',
            mobile: true,
            path: "/products?category=PLAQUES",
            icon:require('./../../assets/media/icons/PLAQUE NEGATIF.png')
          },
          {
            name: 'Hôttes',
            mobile: true,
            path: "/products?category=HOTTES",
            icon:require('./../../assets/media/icons/HOTTE NEGATIF.png')
          },
          {
            name: 'Laves vaisselles',
            mobile: true,
            path: "/products?category=LAVES VAISSELLE",
            icon:require('./../../assets/media/icons/LAVE VAISSELLES NEGATIF.png')
          },
          {
            name: 'Laves linges',
            mobile: true,
            path: "/products?category=LAVES LINGES",
            icon:require('./../../assets/media/icons/LAVE LINGES NEGATIF.png')
          },
          {
            name: 'Réfrégirateurs',
            mobile: true,
            path: "/products?category=RÉFRIGÉRATEURS",
            icon:require('./../../assets/media/icons/FROID NEGATIF.png')
          },
          {
            name: 'Eviers',
            mobile: true,
            path: "/products?category=ÉVIERS",
            icon:require('./../../assets/media/icons/EVIER NEGATIF.png')
          },
          {
            name: 'Mitigeurs',
            mobile: true,
            path: "/products?category=MITIGEURS",
            icon:require('./../../assets/media/icons/MITIGEUR NEGATIF.png')
          },
        ]
      },
      {
        name: 'Recherche de produit',
        mobile: false,
        path: null,
        type: 'submenu',
        children: [
          {
            name: "Par code d'identification du produit",
            mobile: false,
            path: '/products'
          }
        ]
      },
      /* {
        name: 'trouver un revendeur',
        mobile: true,
        path: null,
        type: 'element',
        element: '/support'
      } */
    ]
  },
  {
    name: 'Notre Catalogue',
    type: "plain",
    mobile: true,
    path: "/catalog"
  },
  {
    name: 'Devenir distributeur',
    type: "plain",
    mobile: true,
    path: "/distributor"
  },
  {
    name: 'Support',
    type: "plain",
    mobile: true,
    path: "/support"
  },
]