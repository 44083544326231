import { Button, Form, Input } from "antd"
import DividerComponent from "../shared/components/divider.component"
import PageHeaderComponent from "../shared/components/pageHeader.component"
import img from "./../assets/media/images/KITCHEN6.jpg"

const SupportPage: React.FC = () => {


  var inputClass = " shadow-none !border-b rounded-none !border-[#444] !border-t-transparent !border-l-transparent !border-r-transparent "
  var inputClassHover = " hover:shadow-none hover:!border-b hover:rounded-none hover:!border-[#444] hover:!border-t-transparent hover:!border-l-transparent hover:!border-r-transparent "
  var inputClassFocus = " focus:shadow-none focus:!border-b focus:rounded-none focus:!border-[#444] focus:!border-t-transparent focus:!border-l-transparent focus:!border-r-transparent "

  return (
    <div className="support-page">
      <div className="page-wrapper">
        <PageHeaderComponent src={img} title="Support" />

        <section className="contact-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Contactez nous" />
              <div className="grid grid-cols-1 md:grid-cols-12 gap-5">
                <div className="col-span-1 md:col-span-3">
                  <div className="contact bg-[#444] p-5 h-full">
                    <div className="contact-wrapper">
                      <div className="contact-header text-sm uppercase">
                        <h3>Contact d'assistance</h3>
                        <div className="w-full h-[1px] bg-white my-4"></div>
                      </div>
                      <div className="contact-body py-4 text-sm flex flex-col gap-5">
                        <div className="contact-one flex flex-col gap-2">
                          <p>Adresse email :</p>
                          <p className="font-semibold">contact@focusline.tn</p>
                        </div>
                        {/* <div className="contact-two flex flex-col gap-2">
                          <p>Adresse email :</p>
                          <p className="font-semibold">omar@focusline.tn</p>
                        </div> */}
                        <div className="contact-three flex flex-col gap-2">
                          <p>Chez Focus Électroménagers, votre satisfaction est notre priorité. Notre Service Après-Vente (SAV) est à votre disposition pour toutes vos questions et besoins de support technique. Nous offrons une assistance rapide et efficace, des réparations sous garantie, et des conseils d'entretien pour garantir la durabilité de vos appareils. Contactez notre équipe SAV pour une expérience client sans souci.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 md:col-span-9">
                  <div className="contact bg-white p-5">
                    <div className="contact-wrapper">

                      <Form layout="vertical" className="grid grid-cols-2 gap-5 !font-montserrat uppercase font-medium !text-xs">
                        <Form.Item label="Nom" className="text-xs">
                          <Input className={inputClass + inputClassHover + inputClassFocus} />
                        </Form.Item>
                        <Form.Item label="Prénom" className="text-xs !text-[#444]">
                          <Input className={inputClass + inputClassHover + inputClassFocus} />
                        </Form.Item>
                        <Form.Item label="Objet" className="col-span-2 text-xs">
                          <Input className={inputClass + inputClassHover + inputClassFocus} />
                        </Form.Item>
                        <Form.Item label="Tapez votre message" className="col-span-2 text-xs">
                          <Input.TextArea cols={10} rows={5} className={inputClass + inputClassHover + inputClassFocus} />
                        </Form.Item>

                        <Button className="bg-focus-primary text-white col-span-2 w-24 ml-auto hover:!bg-focus-primary hover:text-white" >Envoyer</Button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="family-story-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Nos locaux" />
              <a href="#item">
                <div className="grid grid-rows-1 lg:grid-cols-12 ">
                  <div className="lg:col-span-5">
                    <div className="image-space overflow-hidden">
                      <iframe className="w-full h-64" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.7520708436514!2d10.301448474947504!3d36.87235287222666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2b51abb9bea25%3A0x3b6a0d0e072a3970!2sFOCUS%20%C3%89lectrom%C3%A9nager!5e0!3m2!1sfr!2stn!4v1716316884246!5m2!1sfr!2stn"></iframe>
                    </div>
                  </div>
                  <div className="lg:col-span-7">
                    <div className="flex flex-col justify-center h-full gap-5 px-5 text-left lg:px-20 py-3 lg:py-6 bg-[#444]">
                      <h3 className="sm:text-2xl text-sm">Focus tunis</h3>
                      <div className="flex flex-col sm:text-sm text-xs md:leading-6 gap-4">
                        <p>Rue Ibn Jazzar, Sidi Daoud 2046 La Marsa Tunis - Tunisie</p>
                        <p>Phone: +216 31 40 38 18</p>
                        <p>Fax: +216 32 40 38 18</p>
                        <p>contact@focusline.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section className="family-story-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <a href="#item">
                <div className="grid grid-rows-1 lg:grid-cols-12 ">
                  <div className="lg:col-span-5">
                    <div className="image-space overflow-hidden">
                      <iframe className="w-full h-64" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.278201850051!2d10.770637574828166!3d34.74858417290267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d3471451cffd%3A0x8eddf3f7155f0f0!2sFOCUS%20de%20commerce%20Sfax!5e0!3m2!1sfr!2stn!4v1718105933206!5m2!1sfr!2stn"></iframe>
                    </div>
                  </div>
                  <div className="lg:col-span-7">
                    <div className="flex flex-col justify-center h-full gap-5 px-5 text-left lg:px-20 py-3 lg:py-6 bg-[#444]">
                      <h3 className="sm:text-2xl text-sm">Focus sfax</h3>
                      <div className="flex flex-col sm:text-sm text-xs md:leading-6 gap-4">
                        <p>Route Gremda Ceinture Bourguiba Km 3 – 3062 Sfax</p>
                        <p>Phone: +216 31 40 38 18</p>
                        <p>Fax: +216 32 40 38 18</p>
                        <p>contact@focusline.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

      </div>
    </div>
  )
}

export default SupportPage